// ------------------------------
// グローバル検索エリア設定の上書き
// ------------------------------
body {
  .navbar-custom {
    z-index: 1020;
  }

  .app-search {
    box-sizing: border-box;
    padding: 16px 16px 16px 0;

    // -----------------------------------------------
    // 検索キーワード・検索BOXのコントローラー
    // -----------------------------------------------
    &-base {
      align-items: center;
      display: flex;
      max-width: 100%;
      position: relative;
      width: 100%;
    }

    &-btns {
      .btn {
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 25px;
        font-size: 13px;
        font-weight: bold;
        margin-right: 4px;
        padding: 4px 0;
        transition: background 300ms, border 300ms;
        width: 110px;
        &-exec {
          background: #008bcc;
          border: 2px #008bcc solid;
          color: #fff;
          &:hover {
            background: #49a2ce;
            border: 2px #49a2ce solid;
          }
        }
        &-toggle {
          background: #333;
          border: 2px #333 solid;
          color: #fff;
          &:hover {
            background: #555;
            border: 2px  #555 solid;
            color: #fff;
          }
          &.is-active {
            background: #333;
            border: 2px #333 solid;
            color: #333;
            &:hover {
              background: #555;
              border: 2px  #555 solid;
              color: #333;
            }
          }
        }
      }
    }

    &-keyword {
      position: relative;
      .ico {
        position: absolute;
      }
      input {
        box-sizing: border-box;
        border: 2px #e8e8e8 solid;
        border-radius: 4px;
        color: #333;
        font-size: 14px;
        height: 38px;
        margin-right: 8px;
        padding: 15px 15px 15px 35px;
        width: 450px;
        &::placeholder {
          color: #ddd;
          font-size: 14px;
          font-weight: bold;
          opacity: 1;
        }
      }
      .ico {
        color: #ccc;
        font-size: 21px;
      }
    }

    // -----------------------------------------------
    // 詳細な検索条件フィルター
    // -----------------------------------------------
    &-filter {
      $this: &;
      align-items: flex-start;
      background: rgba(0,0,0,.9);
      box-sizing: border-box;
      color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      left: 0;
      opacity: 1;
      // overflow: hidden;
      padding: 35px 30px 25px 290px;
      position: fixed;
      top: -1500px;
      transition: opacity 300ms, top 500ms;
      width: 100%;
      z-index: 1015;
      &.is-active {
        opacity: 1;
        overflow: visible;
        top: 70px;
      }

      &-group {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
        &-btns {
          position: absolute;
          top: 7px;
          right: 10px;
          .btn-close {
            color: #fff;
            font-size: 20px;
          }
        }
      }
      &-input-label {
        font-size: 11px;
        font-weight: bold;
        margin: 0 0 5px 0;
        min-height: 17px;
        padding-left: 3px;
      }
      &-input-group {
        margin: 0 25px 20px 0;
        padding: 0;
        // 複数で1個の項目（まとめて折り返させる
        &-unit {
          align-items: flex-start;
          display: flex;
          justify-content: flex-start;
          &.date {
            width: 298px;
          }
          &.price {
            width: 375px;
          }
        }
        &.project-type,
        &.interview-type,
        &.participation-type {
          max-width: 180px;
          width: 180px;          
        }
        &.search-type,
        &.work-type {
          max-width: 150px;
          width: 150px;
        }
        &.category {
          max-width: 320px;
          width: 320px;
        }
        &.location {
          max-width: 200px;
          width: 200px;
        }
        &.category {
          max-width: 400px;
          width: 400px;
          .form-group {
            .app-search-filter-custom-select-option-item {
              width: 33%;
            }
          }
        }
        &.location {
          max-width: 200px;
          width: 200px;
          .form-group {
            .app-search-filter-custom-select-option-item {
              width: 33%;
            }
          }
        }
        &.price-type {
          max-width: 100px;
          width: 100px;
        }
        &.price-amount {
          width: 150px;
        }
        &.price-evaluate-type {
          max-width: 80px;
          width: 80px;
        }
        &.project-owner {
          width: 250px;
        }
      }
      &-input {
        background: transparent !important;
        border-radius: 0;
        border: none;
        border-bottom: 1px #fff solid !important;
        box-sizing: border-box;
        color: #fff;
        font-size: 13px;
        // font-weight: bold;
        height: 32.5px;
        padding: 0 0 1px 0;
        width: 100%;
        &:focus {
          color: #fff;
          border-bottom: 1px #fff solid;
        }
      }


      // select: 単数選択専用
      &-select {
        background: transparent;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        // font-weight: bold;
        margin-right: 10px;
        width: 100%;
        [class$="menu"] {
          color: #333;
          cursor: pointer;
        }
        [class$="control"] {
          background: transparent;
          border: none;
          border-bottom: 1px #fff solid;
          border-radius: 0;
          box-shadow: none;
          color: #333;
          cursor: pointer;
          min-height: auto;
          &:active {
            border: none;
          }
          div {
            padding-left: 0;
            padding-right: 0;
          }
        }
        [class$="placeholder"] {
          color: #fff;
        }
        [class$="singleValue"] {
          color: #fff;
          // font-weight: bold;
        }
        [class$="indicatorSeparator"] {
          display: none;
        }
        [class$="indicatorContainer"] {
          color: #fff;
          padding: 8px 0 8px 8px !important;
          svg {
            height: 12px;
            width: 12px;
          }
        }
      }

      &-custom-select {
        background: transparent;
        border-bottom: 1px #fff solid;
        border-radius: 0;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        font-weight: bold;
        height: 32.5px;
        position: relative;
        &-value {
          align-items: center;
          display: flex;
          justify-content: space-between;
          &-item {
            @include ellipsis;
            font-size: 13px;
            font-weight: normal;
            flex-grow: 2;
            margin-top: 3px;
          }
          &-control {
            align-items: center;
            display: flex;
            justify-content: flex-end;
          }
          &-total {
            align-items: center;
            background: #fff;
            border-radius: 25px;
            color: #333;
            display: flex;
            font-size: 10px;
            font-weight: bold;
            height: 13px;
            margin: 1px 5px 0 0;
            justify-content: center;
            letter-spacing: -0.05em;
            padding: 2px 8px;
            width: auto;
          }
          .btn-reset {
            box-shadow: none;
            color: #fff;
            font-size: 13px;
            margin-top: 3px;
            transition: transform 300ms;
            &.is-active {
              transform: rotate(360deg);
            }
          }
          .btn-dropdown {
            box-shadow: none;
            color: #fff;
            font-size: 15px;
            margin-top: 3px;
            transition: transform 100ms;
            &.is-active {
              margin-top: 2px;
              transform: rotate(180deg);
            }
          }
        }
        &-option {
          &-item {
            // padding: 6.5px 0;
            padding: 5px 0;
            width: 50%;
          }
          &-item-group {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            width: 100%;
            &-label {
              border-bottom: 1px #aaa solid;
              padding: 5px 0 12px 0;
              margin-bottom: 8px;
              width: 100%;
            }
          }
          &-item-group-wrapper {
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            max-height: 270px;
            overflow: auto;
            padding: 15px 15px 12px 15px;
            position: relative;
            width: 100%;
          }
        }
        .form-group {
          background: #fff;
          border-radius: 3px;
          box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
          box-sizing: border-box;
          cursor: default;
          display: none;
          // padding: 10px 15px 8px 15px;
          position: absolute;
          top: 38px;
          width: 180%;
          z-index: 1015;
          &.is-active {
            align-items: flex-start;
            display: flex;
            justify-content: flex-start;
            // flex-direction: column;
            flex-wrap: wrap;
          }
          .btn-close {
            color: #333;
            font-size: 16px;
            top: 2px;
            position: absolute;
            right: 5px;
          }
        }
        .form-check-input {
          margin: 2.5px 5px 0 -20px;
        }
        .form-check-label {
          box-sizing: border-box;
          color: #333;
          cursor: pointer;
          display: block;
          font-size: 13px;
          // font-weight: bold !important;
          padding-left: 20px;
        }
      }
    }
  }
}