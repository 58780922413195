// ------------------------------
// パンくずリスト設定の上書き
// ------------------------------
.breadcrumb {
  height: 60px;
  justify-content: flex-end;
  margin: 15px 0 0 0;
  overflow: hidden;
  padding: 0;
  &-item {
    @include ellipsis;
    background: #333;
    border-radius: 25px;
    border: 2px solid #333;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 11px;
    font-weight: bold;
    height: 28px;
    margin: 5px 10px 0 0;
    min-width: 100px;
    max-width: 320px;
    overflow: hidden;
    padding: 4px 15px 0 15px;
    position: relative;
    text-align: center;
    transition: background 300ms, border 300ms, color 300ms;
    a {
      color: #fff;
      font-size: 11px;
      font-weight: bold;
    }
    &:hover {
      background: #555;
      border-color: #555;
      a {
        color: #fff;
      }
    }
  }
  &-item.active {
    background: #fff;
    border: 2px solid #ddd;
    color: #333;
    cursor: default;
    // font-weight: normal;
    margin-right: 0;
    padding: 4px 15px 0 15px;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding: 4px 15px 0 15px;
    &::before {
      display: none;
      //position: absolute;
    }
  }
}

@include media-breakpoint-down(md) {
  .breadcrumb {
    height: auto;
    margin: 22.5px 0 0 0;
    justify-content: flex-start;
    &-item {
      @include ellipsis;
      display: block;
      height: 24px;
      margin: 0 7.5px 7.5px 0;
      max-width: 150px;
      min-width: auto;
      padding: 4px 12px 0 12px;
    }
    &-item.active {
      margin: 0 10px 10px 0;
    }
    .breadcrumb-item + .breadcrumb-item {
      padding-left: 10px;
    }
  }
}