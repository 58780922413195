// -----------------------------------------------
// 募集中の案件から検索する
// -----------------------------------------------
.root {
  .root-content {
    .search-project-items {
      min-width: 100%;
      .card-group {
        .card-wrapper {
          position: relative;
          max-width: 260px;
          margin: 0 12px 12px 0;
          width: 260px;
          a {
            color: #333;
            text-decoration: none;
          }

          .btn-favorite {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.01);
            box-sizing: border-box;
            cursor: pointer;
            height: 40px;
            position: absolute;
            right: 12.5px;
            top: 12.5px;
            width: 40px;
            z-index: 1000;
            &.active {
              i {
                color: #ffc107;
              }
            }
            i {
              color: #eef2f7;
              font-size: 24px;
              transition: color 300ms;
              &:hover {
                color: #ffc107;
              }
            }
          }
        }

        .card {
          background: #fff;
          cursor: pointer;
          height: 100%;
          max-width: 100%;
          transition: height 300ms;
          width: 100%;
          &::before {
            border: 0 solid #333;
            border-radius: 0.25rem;
            // background: #333;
            box-sizing: border-box;
            content: '';
            display: block;
            height: 100%;
            opacity: 0;
            position: absolute;
            transition: border 300ms, opacity 300ms;
            width: 100%;
            z-index: 999;
          }
          
          &:hover {
            &::before {
              border: 8px solid #333;
              opacity: .3;
            }
          }

          &-header {
            padding: 20px 15px 0 15px;
            border: none;
            &::after {
              background-color: #eef2f7;
              content: '';
              display: block;
              height: 2px;
              margin-top: 20px;
              width: 100%;
            }
          }

          .ownedby {
            background: #333;
            border-radius: 2px 0 0 0;
            color: #fff;
            display: block;
            font-size: 10.5px;
            font-weight: bold;
            // padding: 4px 17px;
            padding: 4px 0;
            position: absolute;
            left: 0;
            text-align: center;
            top: 0;
            width: 65px;
            &.company {
              background: #008bcc;
            }
          }

          &-title {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.2em;
            position: relative;
            .row {
              @include ellipsis;
              display: block;
            }
            .number {
              color: #ccc;
              font-size: 12px;
              padding-bottom: 3px;
            }
            .distribution {
              background: #333;
              border-radius: 2px;
              color: #fff;
              font-size: 10.5px;
              font-weight: bold;
              // padding: 4px 17px;
              padding: 4px 0;
              position: absolute;
              right: -1px;
              text-align: center;
              top: -33px;
              width: 85px;
              &.prime {
                background: #ffc107;
              }
              &.client {
                background: #38b48b;
              }
              &.agent {
                background: #b44c97;
              }
            }
          }

          &-img {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &-body {
            padding: 15px 15px;
          }

          &-text {
            .desc {
              font-size: 14px;
              line-height: 1.8em;
              max-height: 120px;
              overflow: hidden;
            }
          }

          &-footer {
            border: none;
            padding: 0px 15px 8px 15px;
            &::before {
              background-color: #eef2f7;
              content: '';
              display: block;
              height: 2px;
              margin-bottom: 12px;
              width: 100%;
            }
            .badge {
              background: #008bcc;
              // border-radius: 2px;
              border-radius: 25px;
              color: #fff;
              font-weight: bold;
              //padding: 9px 11px 8px 11px;
              padding: 6px 12px 6px 12px;
              font-size: 10.5px;
            }
          }

          .detail {
            font-size: 14px;
            .col {
              margin-bottom: 3px;
              max-width: 70%;
              width: 70%;
              &.label {
                font-weight: bold;
                max-width: 30%;
                width: 30%;
              }
              &.price {
                .row {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}


// -----------------------------------------------
// メディアクエリ: MOBILE
// -----------------------------------------------
.root {
  .root-content {
    .search-project-items {
      @include media-breakpoint-down(sm) {
        min-width: 320px;
        width: 100%;
      }

      .card-group {
        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        .card-wrapper {
          @include media-breakpoint-down(sm) {
            max-width: 100%;
            width: 100%;
          }
        }
        @media (min-width: 1390px) and (max-width: 1420px) {
          .card-wrapper:nth-child(4n) {
            margin-right: 0;
          }
        }
        @media (min-width: 1650px) and (max-width: 1680px) {
          .card-wrapper:nth-child(5n) {
            margin-right: 0;
          }
        }
        @media (min-width: 1930px) and (max-width: 1960px) {
          .card-wrapper:nth-child(6n) {
            margin-right: 0;
          }
        }
        @media (min-width: 2200px) and (max-width: 2230px) {
          .card-wrapper:nth-child(7n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
