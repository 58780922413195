// 共通レイアウト設定: フッター
.footer {
  background: #fff;
  border-top: 4px solid #eef2f7;
  left: 0;
  padding: 15px 0 20px 0;
  width: 100%;
  &-nav-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &-item {
      color: #333;
      font-size: 12px;
      font-weight: bold;
      padding: 10px 0;
      &::after {
        content: '/';
        padding: 0 7.5px;
      }
      &:active,
      &:visited,
      &:hover {
        color: #333;
      }
      &:hover {
        text-decoration: underline;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  &-copyright {
    align-items: center;
    color: #333;
    display: flex;
    font-size: 10px;
    font-weight: bold;
    justify-content: center;
    padding: 5px 0 0 0;
    text-align: center;
  }
}

@include media-breakpoint-down(md) {
  .page-title-box {
    margin: 0 0 25px 0;
    padding: 0 0 0 0;
  }
}