//
// Noto Sans JP fonts
//

@font-face {
  font-family: 'Noto Sans JP';
  src: url('../../../fonts/NotoSansJP-Light.otf');
  src: 
    local('Noto Sans JP Light'), local('Noto Sans JP-Light'), 
    url('../../../fonts/NotoSansJP-Light.otf') format('opentype'); 
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('../../../fonts/NotoSansJP-Regular.otf');
  src: 
    local('Noto Sans JP Regular'), local('Noto Sans JP-Regular'), 
    url('../../../fonts/NotoSansJP-Regular.otf') format('opentype'); 
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('../../../fonts/NotoSansJP-Medium.otf');
  src: 
    local('Noto Sans JP Regular'), local('Noto Sans JP-Regular'), 
    url('../../../fonts/NotoSansJP-Medium.otf') format('opentype'); 
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src: url('../../../fonts/NotoSansJP-Bold.otf');
  src: 
    local('Noto Sans JP Regular'), local('Noto Sans JP-Regular'), 
    url('../../../fonts/NotoSansJP-Bold.otf') format('opentype'); 
    font-weight: 700;
  font-style: normal;
}