//
// M PLUS 1p fonts
//
@font-face {
  font-family: 'M PLUS 1p';
  src: url('../../../fonts/MPLUS1p-Light.ttf');
  src: 
    local('M PLUS 1p Light'), local('M PLUS 1p-Light'), 
    url('../../../fonts/MPLUS1p-Light.ttf') format('truetype'); 
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'M PLUS 1p';
  src: url('../../../fonts/MPLUS1p-Regular.ttf');
  src: 
    local('M PLUS 1p Regular'), local('M PLUS 1p-Regular'), 
    url('../../../fonts/MPLUS1p-Regular.ttf') format('truetype'); 
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'M PLUS 1p';
  src: url('../../../fonts/MPLUS1p-Regular.ttf');
  src:
    local('M PLUS 1p Regular'), local('M PLUS 1p-Regular'),
    url('../../../fonts/MPLUS1p-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'M PLUS 1p';
  src: url('../../../fonts/MPLUS1p-Medium.ttf');
  src: 
    local('M PLUS 1p Medium'), local('M PLUS 1p-Medium'),
    url('../../../fonts/MPLUS1p-Medium.ttf') format('truetype'); 
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'M PLUS 1p';
  src: url('../../../fonts/MPLUS1p-Bold.ttf');
  src: 
    local('M PLUS 1p Bold'), local('M PLUS 1p-Bold'),
    url('../../../fonts/MPLUS1p-Bold.ttf') format('truetype'); 
    font-weight: 700;
  font-style: normal;
}

