// -----------------------------------------------
// 募集中の案件から検索する
// -----------------------------------------------
.create-company-wrapper {
  box-shadow: none;
  font-size: 16px;
  min-width: 100%;
  .card {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .05);
  }

  .create-company-form-confirm-item + .create-company-form-confirm-item {
    border-top: 1px solid #dee2e6;
    padding-top: 25px;
  }

  .create-company-form {
    &-complete {
      margin: 0 auto;
      max-width: 1200px;
      &-item {
        .mdi-check-all {
          font-size: 45px;
        }
        &-title {
          font-size: 24px;
        }
      }
      .btn-group {
        margin: 25px auto 0 auto;
      }
    }
    &-confirm {
      margin: 0 auto;
      max-width: 1200px;
      &-item {
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        padding: 0 0 20px 0;
        width: 100%;
        &-label {
          font-weight: bold;
          min-width: 250px;
          width: 20%;
        }
        &-value {
          width: 78%;
          p {
            margin-bottom: 5px;
          }
          img {
            height: auto;
            max-width: 500px;
            width: 50%;
          }
        }
      }
      .error-list-wrapper {
        margin-bottom: 70px;
      }
    }
    &-input {
      max-width: 1200px;
      margin: 0 auto;
      &-group {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        margin-top: 15px;
        &--required {
          .form-group {
            width: 100%;
            label {
              // ラベル方式
              // &::after {
              //   background: #cc2952;
              //   border-radius: 2px;
              //   box-sizing: border-box;
              //   color: #fff;
              //   content: '必須';
              //   font-size: 9px;
              //   font-weight: bold;
              //   margin-left: 10px;
              //   padding: 3px 7.5px;
              // }
              &::after {
                color: #cc2952;
                content: '※ 必須入力';
                font-size: 10px;
                font-weight: bold;
                margin-left: 8px;
              }
            }
            .input-field {
              max-width: 100%;
              width: 100%;
              &--establish-date {
                width: 250px;
              }
              &--name-first,
              &--name-last,
              &--kana-first,
              &--kana-last {
                width: 350px;
              }
              &--zipcode {
                text-align: center;
                width: 100px;
              }
              &--overview {
                height: 200px;
                resize: none;
              }
              &--establishment-on-year {
                width: 100px;
                text-align: center;
              }
              &--establishment-on-month {
                width: 80px;
                text-align: center;
              }
            }
          }
        }
        &--double {
          .form-group {
            width: 350px;
            max-width: 49%;
            &:first-child {
              margin-right: 2%;
            }
          }
        }
        &--establishment-on {
          align-items: flex-end;
          .form-group {
            width: auto;
            &:first-child {
              margin-right: 0;
            }
            &-unit {
              align-items: center;
              display: flex;
              font-size: 14px;
              padding: 0 15px 0 10px;
              height: 72px;
              &--year {
                margin-left: -13px; 
              }
            }
            &.view-error {
              width: 100%;
              position: absolute;
            }
            &.text-danger {
              .invalid-feedback {
                display: none;
                &--active {
                  display: flex;
                  right: auto;
                  top: -38px;
                  left: 260px;
                }
                &::before {
                  border: 5px solid transparent;
                  border-right: 6px solid #cc2952;
                  top: 9px;
                  content: '';
                  left: -10px;
                  margin-bottom: 7px;
                  position: absolute;
                  z-index: 2100;
                }
              }
            }
          }
        }
        &--address {
          .form-group {
            &.text-danger {
              .invalid-feedback {
                right: auto;
                top: 30px;
                left: 115px;
                &::before {
                  border: 5px solid transparent;
                  border-right: 6px solid #cc2952;
                  top: 9px;
                  content: '';
                  left: -10px;
                  margin-bottom: 7px;
                  position: absolute;
                  z-index: 2100;
                }
              }
            }
          }
        }
      }
    }
  }

  // -------------------------------------
  // 共通設定: フォーム入力・選択パーツ
  // -------------------------------------
  .form-group {
    position: relative;

    // バリデーションエラー時の表示
    &.text-danger {
      label {
        color: #333;
      }
      input,
      textarea {
        border-color: #cc2952;
      }
      .react-select,
      .react-select-multiple {
        &__control {
          border-color: #cc2952;
        }
        &__menu {
          color: #333;
        }
      }
      .invalid-feedback {
        background: #cc2952;
        border-radius: 4px;
        color: #fff;
        display: flex;
        font-size: 12px;
        justify-content: center;
        top: -10px;
        padding: 4px 15px;
        position: absolute;
        right: 0;
        width: auto;
        &::before {
          border: 5px solid transparent;
          border-top: 6px solid #cc2952;
          bottom: 0px;
          content: '';
          margin-bottom: -10px;
          position: absolute;
          z-index: 2100;
        }
      }
    }
    label {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: bold !important;
      justify-content: flex-start;
    }
    input,
    textarea {
      border: 1px solid #dee2e6;
      border-radius: 4px;
      font-size: 14px;
      color: #333;
      &.is-invalid {
        background-image: none;
      }
      &:focus {
        border: 1px solid #49a2ce !important;
      }
    }
    input {
      height: 38px;
      padding: 4px 8px;
    }
    textarea {
      padding: 8px;
    }
    .react-select,
    .react-select-multiple {
      &__indicator {
        cursor: pointer;
      }
      &__placeholder,
      &__value-container {
        font-size: 14px;
      }
      &__control {
        border: 1px solid #dee2e6;
        border-radius: 4px;
        &--menu-is-open {
          border: 1px solid #49a2ce !important;
        }
      }
      &__menu {
        font-size: 14px;
      }
      &__multi-value {
        background-color: #008bcc !important;
        color: #fff !important;
        cursor: pointer;
        &__label {
          color: #fff !important;
          font-size: 12px;
          font-weight: bold;
        }
      }
      input[type=text] {
        height: auto;
      } 
    }
  }
  .react-select-multiple {
    &__value-container {
      padding: 2px 4px;
    }
    &__control {
      box-shadow: none !important;
    }
  }

  // -------------------------------------
  // フォームのボタンエリア
  // -------------------------------------
  .btn-group {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 45px 0 45px 0;
    .btn {
      box-sizing: border-box;
      box-shadow: none !important;
      border-radius: 25px;
      font-size: 14px;
      font-weight: bold;
      margin: 0 7.5px;
      padding: 10px 0;
      transition: background 300ms, border 300ms;
      width: 250px;
      &-next {
        background: #008bcc;
        border: 2px #008bcc solid;
        color: #fff;
        &:hover,
        &:active,
        &:focus {
          background: #49a2ce;
          border: 2px #49a2ce solid;
        }
      }
      &-previous {
        background: #333;
        border: 2px #333 solid;
        color: #fff;
        &:hover,
        &:active,
        &:focus {
          background: #555;
          border: 2px #555 solid;
        }
      }
    }
  }

  // -------------------------------------
  // 進捗バーエリア
  // -------------------------------------
  .progress-form-wrapper {
    width: 100%;
  }

  .progress-form {
    &-list {
      align-items: center;
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin: 15px 0 30px 0;
      padding: 0;
      &-item {
        align-items: center;
        color: #ccc;
        display: flex;
        font-size: 18px;
        font-weight: bold;
        justify-content: center;
        width: 34%;
        &::before {
          align-items: center;
          background: #fff;
          border: 4px #ccc solid;
          border-radius: 50%;
          box-sizing: border-box;
          color: #ccc;
          content: attr(data-text);
          display: flex;
          font-size: 16px;
          font-weight: bold;
          height: 30px;
          margin-right: 12.5px;
          justify-content: center;
          width: 30px;
          padding: 15px 15px;
        }
        &.is-active {
          color: #333;
          &::before {
            background: #008bcc;
            border: 4px #008bcc solid;
            color: #fff;
          }
        }
      }
    }
  }

  .progress {
    margin-bottom: 80px !important;
    &-bar {
      background-color: #00aeff;
    }
  }
}

// -----------------------------------------------
// メディアクエリ: MOBILE
// -----------------------------------------------
// .root {
//   .root-content {
//     .search-project-items {
//       @include media-breakpoint-down(sm) {
//         min-width: 320px;
//         width: 100%;
//       }

//       .card-group {
//         @include media-breakpoint-down(sm) {
//           flex-direction: column;
//         }

//         .card-wrapper {
//           @include media-breakpoint-down(sm) {
//             max-width: 100%;
//             width: 100%;
//           }
//         }
//         @media (min-width: 1390px) and (max-width: 1420px) {
//           .card-wrapper:nth-child(4n) {
//             margin-right: 0;
//           }
//         }
//         @media (min-width: 1650px) and (max-width: 1680px) {
//           .card-wrapper:nth-child(5n) {
//             margin-right: 0;
//           }
//         }
//         @media (min-width: 1930px) and (max-width: 1960px) {
//           .card-wrapper:nth-child(6n) {
//             margin-right: 0;
//           }
//         }
//         @media (min-width: 2200px) and (max-width: 2230px) {
//           .card-wrapper:nth-child(7n) {
//             margin-right: 0;
//           }
//         }
//       }
//     }
//   }
// }
