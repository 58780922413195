body {
  // background: #fff;
  background: #fafbfe;
  color: #333;
  font-family: 'Roboto', 'Noto Sans JP', Meiryo, メイリオ, sans-serif;
  // font-family: 'Roboto', 'M PLUS 1p', Meiryo, メイリオ, sans-serif;
  // font-family: 'Roboto', Meiryo, メイリオ, 'M PLUS 1p', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  a {
    -webkit-tap-highlight-color:rgba(0,0,0,0);
  }

  *:focus {
    outline: none !important;
  }

  .left-side-menu {
    box-shadow: none;
    z-index: 1030;
  }

  .content-page {
    position: relative;
    padding-bottom: 200px;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23ccc%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpath%20d%3D%22M6%209l6%206%206-6%22%2F%3E%3C%2Fsvg%3E');
    background-position: right 0.7rem center;
    background-repeat: no-repeat;
    background-size: 12.5px auto;
    box-sizing: border-box;
  }

  .left-side-menu {
    padding-top: 120px;
    .logo-sidebar {
      height: auto;
      margin: 5px 0 0 28px;
      width: 120px;
      img {
        width: 100%;
      }
    }
  }

  .form-control {
    border-color: #ccc;
  }

  .loader-wrapper {
    width: 100%;
    .preloader {
      background: rgba(255, 255, 255, .5);
    }
    .bouncing-loader {
      div {
        background: #00aeff !important;
      }
      div:nth-child(2) {
        background: #008bcc !important;
      }
      div:nth-child(3) {
        background: #006999 !important;
      }
    }
  }

  .error-message-wrapper {
    width: 100%;
    .row {
      width: 100%;
    }
    .error-title {
      font-size: 24px;
      //font-weight: bold;
      justify-content: center;
    }
    .error-message {
      justify-content: center;
      font-size: 16px;
    }
  }

  .empty-message-wrapper {
    width: 100%;
    .row {
      width: 100%;
    }
    .empty-title {
      font-size: 24px;
      //font-weight: bold;
      justify-content: center;
    }
    .empty-message {
      justify-content: center;
      font-size: 16px;
    }
  }

  .pagination {
    margin: 0;
    &-wrapper {
      align-items: center;
      display: flex;
    }

    // ページャーリンク
    .page {
      &-item {
        border-color: #ccc;        
      }
      &-link {
        align-items: center;
        border-color: #ccc;
        box-sizing: border-box;
        color: #333;
        display: flex;
        font-size: 14px;
        font-weight: 800;
        height: 36px;
        justify-content: center;
        min-width: 36px;
        padding: 0.5rem 0rem;
        transition: background 300ms;
        &:hover {
          background: #eef2f7;
        }
      }
    }
    .page-item.disabled {
      .page-link {
        color: #ccc;
        border-color: #ccc;
      }
    }
    .page-item.active {
      .page-link {
        background: #008bcc;
        border: #008bcc;
        &:hover {
          background: #008bcc;
        }
      }
    }

    // ページャー表示数設定セレクター
    &-select-per {
      align-items: center;
      display: flex;
      &-label {
        color: #333;
        font-size: 14px;
        font-weight: 700;
        padding: 1px 10px 0 0;
        margin: 0;
      }
      select {
        color: #333;
        font-size: 14px;
        font-weight: 800;
        height: 36px !important;
        width: 85px;
      }
    }

    // ページャーサマリー情報
    &-summary {
      align-items: center;
      display: flex;
      color: #333;
      font-size: 14px;
      font-weight: 700;
      .row {
        margin: 0 10px 0 0;
      }
    }
  }

  .error-list-wrapper {
    background: #fdeff2;
    box-sizing: border-box;
    border-radius: 2px;
    display: none;
    padding: 25px 35px 25px 25px;
    position: relative;
    margin: -10px 0 35px 0;
    width: 100%;
    &.is-active {
      display: flex;
    }
    .btn-close {
      background: transparent;
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      cursor: pointer;
      height: 20px;
      position: absolute;
      right: 10px;
      top: 7px;
      width: 20px;
      z-index: 1000;
      &:active {
        border: none;
      }
      i {
        color: #a22041;
        font-size: 20px;
        transition: color 300ms;
        &:hover {
          color: #a22041;
        }
      }
    }
  }

  .error-list {
    margin: 0;
    padding: 0;
    &-item {
      color: #a22041;
      display: block;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .side-nav .side-nav {
    &-item {
      .side-nav-link {
        border-bottom: 1px rgba(255,255,255, 0.05) solid;
        font-size: 14px;
        font-weight: bold;
        padding: 20px 30px;
        i {
          font-size: 16px;
        }
      }
      &:last-child {
        .side-nav-link {
          border: none;
        }
      }
    }
  }

  .dropzone {
    border-radius: 4px;
    border-style: dotted;
    border-width: 2px;
    margin-top: 10px;
    .dz-message {
      padding: 15px 20px 25px 20px;
      .dripicons-cloud-upload {
        color: #dee2e6;
        font-size: 50px;
      }
      .description {
        color: #ccc;
        font-size: 12px;
        margin-top: 10px;
        p {
          margin-bottom: 9px;
        }
      }
    }
    &-previews {
      position: relative;
      &-inner {
        display: flex;
      }
      &-image {
        max-width: 500px;
        width: 50%;
        img {
          height: auto;
          width: 100%;
        }
        &-data {
          font-size: 14px;
        }
      }
      .btn-delete {
        box-shadow: none;
        color: #333;
        font-size: 18px;
        padding: 0;
        position: absolute;
        right: 9px;
        top: 5px;
        transition: opacity 300ms, transform 500ms;
        &:hover {
          opacity: .9;
          // transform: rotate(180deg);
        }
      }
    }
  }
}
