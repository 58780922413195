// 共通レイアウト設定: ページタイトル
.page-title-box {
  border-bottom: 4px solid #eef2f7;
  line-height: 1em;
  margin: -45px 0 45px 0;
  padding: 0 0 0 0;
  .page-title {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -0.03em;
  }    
}

@include media-breakpoint-down(md) {
  .page-title-box {
    margin: 0 0 25px 0;
    padding: 0 0 0 0;
  }
}